<template>
  <picture>
    <template
        v-if="props.srcset"
        v-for="(src, index) in parseSrcset(props.srcset)"
        :key="index"
    >
      <source :srcset="src.path" :media="`(max-width: ${src.width}px)`"/>
    </template>
    <img

        :class="`${props.class} ${objectFit ? 'object-contain' : 'object-cover'}`"
        class="pointer-events-none overlay"
        :style="` ${
        img?.hasFocalPoint
          ? `object-position: ${img?.focalPoint
              .map((entry) => (entry * 100).toFixed(2).toString() + '% ')
              .join(' ')}`
          : ''
      } `"
        :src="props.src && props.src?.includes('://') ? props.src : origin + props.src"
        :alt="props.alt ? props.alt : ''"
        :title="props.title ? props.title : ''"
        :loading="props.isNotLazy ? 'eager' : 'lazy'"
        :fetchpriority="props.isNotLazy ? 'high' : 'low'"
    />
  </picture>
  <v-overlay v-if="enableOverlay" :activator="'parent'" v-model="showOverlay" :id="props.src" @click="hideOverlay" scroll-strategy="close" height="100dvh" width="100dvw">
    <v-container class="flex h-screen flex-wrap">
        <v-img
            justify-center
            align-center
            :src="props.src"
            :aspect-ratio="16/9"
            contain
        />
    </v-container>
  </v-overlay>
</template>

<script setup lang="ts">
import {Image} from "@/types/elements";
import {computed, watchEffect} from "vue";
import {useHead} from "@unhead/vue";
import {ref} from "vue";

const props = defineProps<{
  src: string;
  srcset?: string;
  alt?: string;
  title?: string;
  class: string;
  objectFit?: boolean | false;
  img?: Image;
  isNotLazy?: boolean;
  overlay?: boolean;
}>();

const origin = location.origin;
const showOverlay = ref(false);
const selectedImage = ref("");
const enableOverlay = ref(props.overlay && window.innerWidth >= 1024);

watchEffect(() => {
  const handleResize = () => {
    enableOverlay.value = props.overlay && window.innerWidth >= 1024;
  };
  window.addEventListener('resize', handleResize);
  handleResize(); // Initial call to set the correct state
  return () => {
    window.removeEventListener('resize', handleResize);
  };
});

function toggleOverlay(src: string) {
  console.log('toggleOverlay', src);
  if (props.overlay && window.innerWidth >= 1024) {
    selectedImage.value = src;
    showOverlay.value = true;
  }
}

function hideOverlay() {
  showOverlay.value = false;
}

function parseSrcset(srcset: string): { path: string; width: number }[] {
  return srcset.split(",").map((entry) => {
    entry = entry.trim()
    if (!entry) return;
    const [path, width] = entry.trim().split(" ");
    return {path, width: parseInt(width.replace("w", ""))};
  });
}

const preloadHref = computed(() => {
  if (props.srcset) {
    const parsedSrcset = parseSrcset(props.srcset);
    if (parsedSrcset.length > 0) {
      return parsedSrcset[0].path;
    }
  }
  return props.src;
});

if (props.isNotLazy) {
  useHead({
    link: [
      {
        rel: "preload",
        as: "image",
        href: preloadHref.value,
        fetchpriority: "high",
      },
    ],
  });
}
</script>

<style>
.custom-img img {

}
</style>
