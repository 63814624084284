import { createVuetify } from 'vuetify'
import { VBtn, VDivider, VSelect, VTextField, VTextarea, VCheckbox, VRadioGroup, VRadio, VRangeSlider, VPagination, VTooltip, VDatePicker, VDialog, VCard, VCardActions, VCardText, VProgressLinear, VProgressCircular, VIcon, VSnackbar, VOverlay, VImg, VLayout} from 'vuetify/components'
import { Intersect } from 'vuetify/directives'

const vuetify = createVuetify({
    components: { VBtn, VDivider, VSelect, VTextField, VTextarea, VCheckbox, VRadioGroup, VRadio, VRangeSlider, VPagination, VTooltip, VDatePicker, VDialog, VCard, VCardActions, VCardText, VProgressLinear, VProgressCircular, VIcon, VSnackbar, VOverlay, VImg, VLayout },
    directives: { Intersect },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: "#E30613",
                    dark: "#1B2125",
                    text: "#1B2125",
                    light: "#ffffff",
                },
                variables: {
                    'medium-emphasis-opacity': 0.9,
                }
            },
        },
    },
    aliases: {
        VBtnLite: VBtn,
        VBtnLiteDark: VBtn,
        VBtnLiteDarkIcon: VBtn,
        VBtnInverted: VBtn,
        VBtnInvertedLight: VBtn,
        VBtnLiteIcon: VBtn,
        VBtnTransparent: VBtn,
        VSelectIcon: VSelect,
    },
    defaults: {
        global: {
            ripple: false,
            flat: true,
            elevation: 0,
            style: "letter-spacing: 0em;"
        },
        VBtn: {
            color: 'primary',
            rounded: true,
            density: 0,
            class: "h-auto !font-bold !tracking-normal"
        },
        VBtnInverted: {
            color: 'dark',
            rounded: true,
            density: 0,
            class: "!font-bold !tracking-normal"
        },
        VBtnInvertedLight: {
            color: 'dark',
            rounded: true,
            density: 0,
            class: "btn-inverted-light !font-bold !tracking-normal"
        },
        VBtnLite: {
            color: 'light',
            rounded: true,
            density: 0,
            class: "btn-lite !font-bold !tracking-normal"
        },
        VBtnLiteDark: {
            color: 'light',
            rounded: true,
            density: 0,
            class: "btn-lite-dark v-btn--size-custom !font-bold !tracking-normal"
        },
        VBtnLiteIcon: {
            color: 'light',
            density: 0,
            class: "btn-lite btn-lite-icon w-10 h-10 v-btn--icon-padding !tracking-normal"
        },
        VBtnLiteDarkIcon: {
            color: 'light',
            density: 0,
            size: 'custom',
            class: "btn-lite-dark btn-lite-icon w-10 h-10 v-btn--icon-padding !tracking-normal"
        },
        VBtnTransparent: {
            color: 'transparent',
            density: 0,
            class: "btn-transparent h-auto !text-xs !underline !tracking-normal"
        },
        VDivider: {
            class: 'border-black'
        },
        VSelect: {
            rounded: true,
            bgColor: "transparent",
            variant: "solo",
            menuIcon: "mdi-chevron-down",
            class: "v-select"
        },
        VSelectIcon: {
            bgColor: "transparent",
            variant: "solo",
            class: "v-select-icon"
        },
        VTextField: {
            rounded: true,
            bgColor: "transparent",
            variant: "solo"
        },
        VTextarea: {
            bgColor: "transparent",
            variant: "solo",
        },
        VCheckbox: {
            hideDetails: true
        },
        VRadioGroup: {
            ripple: false
        },
        VRangeSlider: {
            ripple: false
        },
        VPagination: {
            ripple: false
        },
        VTooltip: {
            color: "#1B2125"
        }
    },
})

export default vuetify;